const collapser = document.getElementById('timeline-collapser');
const workspace = document.getElementById('issue-workspace');
const itemsSelector = '#messages > .timeline-item';
const moreIcon = 'unfold_more';
const lessIcon = 'unfold_less';

function collapse() {
  document.querySelectorAll(itemsSelector).forEach(function(item){
    item.classList.remove('expand');
    item.classList.add('shrink');
  });
  if (collapser) {
    collapser.innerText = moreIcon;
  }
}

function expand() {
  document.querySelectorAll(itemsSelector).forEach(function(item){
    item.classList.remove('shrink');
    item.classList.add('expand');
  });
  if (collapser) {
    collapser.innerText = lessIcon;
  }
}

if (collapser) {
  collapser.addEventListener('click', function() {
    collapser.innerText == moreIcon ? expand() : collapse();
  });
}

function findAncestor (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

[].forEach.call(document.querySelectorAll('.crm-message .crm-message--head'), function(message_body){
  message_body.addEventListener('click', function(event) {
    if (event.target.tagName == 'A') { return; }
    const parent = findAncestor(message_body, 'timeline-item');
    parent.classList.add('shrink');
    parent.classList.remove('expand');
  });
});

[].forEach.call(document.querySelectorAll('.crm-message .body-part-excerpt .expand-body'), function(message_body){
  message_body.addEventListener('click', function(event) {
    var parent = findAncestor(message_body, 'timeline-item');
    parent.classList.remove('shrink');
    parent.classList.add('expand');
  });
});

const cssAbbreviated = 'shrunk';
[].forEach.call(document.querySelectorAll('.timeline-abbr'), function(abbr){
  abbr.addEventListener('click', function(event) {
    const timeline = abbr.parentElement;
    Array.from(timeline.getElementsByClassName(cssAbbreviated)).forEach(el => el.classList.remove(cssAbbreviated));
    abbr.classList.add('hidden');
  });
});
