import Rails from '@rails/ujs';
import {snackbar} from 'snackbar-util';
import {MDCDialog} from '@material/dialog';
import {setupUnloadConfirmation, markAsUnsaved, markAsSaved} from 'unload-confirmation';
import { wrapOverflow } from 'wrap-overflow';
import {CrmAttachments} from './crm-attachments';
import {listenCopyPaste} from './message-copy-paste';

function setRecordID(el, id) {
  ['formaction', 'href', 'data-path'].some(name => {
    const attr = el.getAttribute(name);
    if (attr) {
      el.setAttribute(name, attr.replace(/responses\/\d+/, `responses/${id}`));
    }
    return attr;
  });
}

function renderCompose(form, attachments, record) {
  const title = form.querySelector('[role="title"]');
  const errors = form.querySelector('[role="errors"]');
  const defect = form.querySelector('[role="defect"]');
  const due = form.querySelector('[role="due"]');

  // show/hide buttons and selects
  if (record.persisted) {
    form.querySelectorAll('[role="new-record"]').forEach(function(el){
      el.classList.add('hidden');
    });
    form.querySelectorAll('[role="persisted"]').forEach(function(el){
      setRecordID(el, record.id);
      if (el.getAttribute('use') == 'close' && !record.closeable) {
        el.classList.add('hidden');
      } else {
        el.classList.remove('hidden');
      }
    });
  } else {
    form.querySelectorAll('[role="persisted"]').forEach(function(el){
      el.classList.add('hidden');
    });
    form.querySelectorAll('[role="new-record"]').forEach(function(el){
      if (el.getAttribute('use') == 'close' && !record.closeable) {
        el.classList.add('hidden');
      } else {
        el.classList.remove('hidden');
      }
    });
  }

  // flash message
  if (record.flash) {
    snackbar(record.flash);
  }

  // errors
  if (record.errors.length) {
    errors.innerText = record.errors.join(', ');
    errors.classList.remove('hidden');
  } else {
    errors.innerText = '';
    errors.classList.add('hidden');
  }
  // defect
  if (record.defect) {
    defect.innerText = record.defect;
    defect.classList.remove('hidden');
  } else {
    defect.innerText = '';
    defect.classList.add('hidden');
  }

  // title
  if (record.persisted) {
    title.innerHTML = `Edit response <span class="mdc-typography--caption" style="text-transform:none">by ${record.author} saved at ${record.updated_at}</span>`;
  } else {
    title.innerText = 'Write response';
  }

  // due (weekly coaching email)
  if (record.due) {
    due.innerText = record.due;
    due.classList.remove('hidden');
  } else {
    due.innerText = '';
    due.classList.add('hidden');
  }

  // inputs
  const renderedInput = form.querySelector('[role="response-rendered"]');
  const templateInput = form.querySelector('[role="response-template"]');
  const bodyInput = form.querySelector('[role="response-body"]');

  renderedInput.value = record.rendered;
  templateInput.value = record.template_id;
  bodyInput.value = record.body;

  // attachments
  if (record.persisted) {
    attachments.fetch();
  }
  // template for preview
  const previewDialog = form.querySelector('[role="preview-dialog"]');
  previewDialog.querySelector('[role="email-template"]').value = record.template_id;
}

function setPreviewRendered(preview, rendered) {
  const editResponse = preview.querySelector('[role="edit-response"]');
  const template = preview.querySelector('[role="email-template"]');
  const mdcSelect = template.parentElement;

  if (rendered) {
    editResponse.disabled = true;
    editResponse.title = "Can't edit response part because the response has edited with template";
    // template.disabled = true;
    template.title = "Can't change template because the response has edited with template";
    mdcSelect.classList.add('mdc-select--disabled');
  } else {
    editResponse.disabled = false;
    editResponse.title = '';
    template.disabled = false;
    template.title = '';
    mdcSelect.classList.remove('mdc-select--disabled');
  }
}

function renderPreview(preview, dialog, response) {
  const email = response.preview;

  // template options
  preview.querySelector('[role="email-template"]').value = email.template_id;
  preview.querySelector('[role="email-subject"]').value = email.subject;
  preview.querySelector('[role="email-from"]').value = email.from;
  preview.querySelector('[role="email-to"]').value = email.to;
  preview.querySelector('[role="email-cc"]').value = email.cc;
  preview.querySelector('[role="email-bcc"]').value = email.bcc;
  preview.querySelector('[role="body-text"]').innerText = email.text;
  preview.querySelector('[role="body-text-hidden"]').value = email.text;

  setPreviewRendered(preview, response.rendered);

  const bodyHtml = preview.querySelector('[role="body-html"]');
  bodyHtml.innerHTML = email.html;
  wrapOverflow(bodyHtml);

  if (!dialog.isOpen) {
    dialog.open();
  }
}

function closeIssue() {
  const closer = document.getElementById('close-issue-form');
  if (closer) {
    closer.submit();
  } else {
    snackbar("Respose is saved, but can't close the issue");
  }
}

const form = document.getElementById('issue-response-compose');

if (form) {
  const messages =  document.getElementById('messages');
  const attachments = new CrmAttachments(form);
  const refreshURL = form.dataset.refresh;
  const previewButton = form.querySelector('[role="preview-button"]');
  const aside = form.querySelector('[role="preview-dialog"]');
  const bodyInput = form.querySelector('textarea[role="response-body"]');
  const dialog = new MDCDialog(aside);
  dialog.autoStackButtons = false;

  function refreshCompose() {
    Rails.ajax({
      url: refreshURL, type: 'GET',
      success: response => renderCompose(form, attachments, response),
      error: response => snackbar(response)
    });
  }

  document.addEventListener("DOMContentLoaded", function(){
    setupUnloadConfirmation(bodyInput);
    refreshCompose();
  });

  form.querySelectorAll('input[type="text"]').forEach(function(input) {
    input.addEventListener('keypress', function(event) {
      if (event.key == 'Enter') {
        event.preventDefault()
      }
    })
  });

  form.addEventListener('ajax:success', function(event) {
    const { target, detail } = event;
    if (form != target) return; // the form contains other ajax elements

    const response = detail[0];
    const xhr = detail[2];
    const url = new URL(xhr.responseURL);
    const flag = url.searchParams.get('flag');

    if (xhr.responseURL.match(/\/deliver/i)) {
      markAsSaved(bodyInput);
      messages.appendChild(response.querySelector('.timeline-item'));
      refreshCompose();
      document.getElementById('issue-response-sendnow-hint').classList.remove('hidden');
    } else if (response.preview) {
      renderPreview(aside, dialog, response);
    } else {
      markAsSaved(bodyInput);
      renderCompose(form, attachments, response);
      if (flag == 'close') {
        closeIssue();
      } else if (flag == 'deliver') {
        form.querySelector('[use="deliver"]').click();
      }
    }
  });

  form.addEventListener('ajax:error', function(event) {
    const { target, detail } = event;
    if (form != target) return;
    snackbar(detail[0]);
  });

  form.querySelectorAll('[use="close"]').forEach(function(button) {
    button.addEventListener('click', function(event) {
      const count = document.querySelectorAll('#messages [id^=message]').length;
      const prompt = `You are going to close ${count} messages. Are you sure?`;
      if (count > 1 && !confirm(prompt)){
        event.preventDefault();
      }
    });
  });

  const template = form.querySelector('[role="email-template"]');
  template.addEventListener('change', () => previewButton.click() );

  const editResponse = aside.querySelector('[role="edit-response"]');
  editResponse.addEventListener('click', function(event) {
    bodyInput.focus();
  });

  const editWhole = aside.querySelector('[role="edit-whole"]');
  editWhole.addEventListener('click', function(event) {
    const renderedInput = form.querySelector('[role="response-rendered"]');
    const templateInput = form.querySelector('[role="response-template"]');
    const templateOption = aside.querySelector('[role="email-template"]');
    const bodyText = aside.querySelector('[role="body-text-hidden"]');

    if (!renderedInput.value || renderedInput.value == 'false' ) {
      renderedInput.value = true;
      templateInput.value = templateOption.value;
      bodyInput.value = bodyText.value;
    }
    setPreviewRendered(aside, true);
  });

  listenCopyPaste(bodyInput);
}
