import {MDCTabBar} from '@material/tab-bar';

document.querySelectorAll('.crm-email-format-tab').forEach(function(element){
  const bar = new MDCTabBar(element);

  element.addEventListener('MDCTabBar:activated', function(data) {
    const messageId = element.getAttribute('data-message');
    const parent = messageId ? document.getElementById(messageId) : element.parentNode;
    const parts = parent.querySelectorAll('.email-parts .email-part');

    for (let index = 0; index < parts.length; index++) {
      const part = parts[index];
      if (index == data.detail.index) {
        part.classList.remove('hidden');
      } else {
        part.classList.add('hidden');
      }
    }
  });
});
