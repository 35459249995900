export default function crmAttachClickable(element) {
  if (element.getAttribute('data-clickable')) return;
  element.setAttribute('data-clickable', true);
  element.addEventListener('click', function(event) {
    const path = element.getAttribute('data-path');

    if (path) {
      const a = document.createElement('a');
      a.href = window.location.href;

      const queryIndex = path.indexOf('?');
      if (queryIndex > 0) {
        a.pathname = path.substring(0, queryIndex);
        a.search = path.substring(queryIndex + 1);
      } else {
        a.pathname = path;
        a.search = '';
      }

      window.location.href = a.href;
	  }
  });
}

document.querySelectorAll('.crm-clickable [data-path]').forEach((element) => {crmAttachClickable(element);});
