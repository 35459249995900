import { Controller } from '@hotwired/stimulus'
import { MDCDataTable } from '@material/data-table'
import { MDCDialog } from '@material/dialog'
import { MDCTextField } from '@material/textfield'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'table', 'button', 'form', 'toggle', 'dialog',
                     'emailId', 'emailSubject', 'emailTo', 'resendForm']

  connect() {
    this.emailTo = new MDCTextField(this.emailToTarget)
    this.dialog = new MDCDialog(this.dialogTarget)
    this.dialog.autoStackButtons = false

    let showButton = false

    if (this.hasTableTarget) {
      this.dataTable = new MDCDataTable(this.tableTarget)
      let showToggle = true

      this.dataTable.getRows().forEach((row) => {
        if (row.hasAttribute('data-requeue')) {
          showButton = true
        } else {
          showToggle = false
        }
      })
      this.toggleTarget.style.visibility = (showToggle ? 'visible' : 'hidden')
    }
    this.buttonTargets.forEach((btn) => {
      btn.style.visibility = (showButton ? 'visible' : 'hidden')
    })
  }

  updateButton() {
    const count = this.dataTable.getSelectedRowIds().length
    if (count === 0) {
      this.buttonTargets.forEach((btn) => {
        btn.disabled = true
        btn.querySelector('.mdc-button__label').innerText = btn.dataset.label
      })
    } else {
      this.buttonTargets.forEach((btn) => {
        btn.disabled = false
        btn.querySelector('.mdc-button__label').innerText = `${btn.dataset.label} (${count})`
      })
    }
  }

  requeue(event){
    const btn = event.currentTarget
    if (confirm(btn.dataset.confirmation) == true) {
      this.formTarget.action = btn.dataset.formaction
      this.formTarget.submit()
    }
  }

  edit({params: {id, to, subject}}){
    this.emailIdTarget.value = id
    this.emailSubjectTarget.innerText = subject
    this.emailToTarget.querySelector('input').value = to

    this.dialog.open()
  }

  resend() {
    Rails.fire(this.resendFormTarget, 'submit')
  }

  resent({ detail }) {
    const attrs = detail[0]
    const row = this.tableTarget.querySelector(`[data-row-id="${attrs.id}"]`)

    const checkbox = row.querySelector('.mdc-checkbox')
    checkbox.querySelector('input').disabled = true
    checkbox.classList.add('mdc-checkbox--disabled')

    const email = row.querySelector('.crm-outbox-list--email')
    email.innerText = attrs.to
    email.removeAttribute('data-action')
    email.classList.remove('crm-outbox-list-editable')

    const status = row.querySelector('.crm-outbox-list--status')
    status.innerText = '-'

    this.toggleTarget.style.visibility = 'hidden'
  }

  unsent({ detail }) {
    const attrs = detail[0]
    const row = this.tableTarget.querySelector(`[data-row-id="${attrs.id}"]`)

    const checkbox = row.querySelector('.mdc-checkbox')
    checkbox.querySelector('input').disabled = true
    checkbox.classList.add('mdc-checkbox--disabled')
    checkbox.setAttribute('data-indeterminate', 'true')

    const email = row.querySelector('.crm-outbox-list--email')
    email.removeAttribute('data-action')
    email.classList.remove('crm-outbox-list-editable')

    const status = row.querySelector('.crm-outbox-list--status')
    status.innerText = attrs.status

    this.toggleTarget.style.visibility = 'hidden'
  }
}
