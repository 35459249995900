const fold = 'crm-fold';
const more = 'unfold_more';
const less = 'unfold_less';

[].forEach.call(document.querySelectorAll('.crm-toggle-icon'), function(toggle) {
  toggle.addEventListener('click', function() {
    var element = document.getElementById(toggle.getAttribute('data-target'));

    if (element.classList.contains(fold)) {
      element.classList.remove(fold);
    } else {
      element.classList.add(fold);
    }

    if (toggle.innerText == 'unfold_more') {
      toggle.innerText = 'unfold_less';
    } else if (toggle.innerText == 'expand_more') {
      toggle.innerText = 'expand_less';
    } else if (toggle.innerText == 'unfold_less') {
      toggle.innerText = 'unfold_more';
    } else if (toggle.innerText == 'expand_less') {
      toggle.innerText = 'expand_more';
    }
  });
});
