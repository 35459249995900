import {MDCTabBar} from '@material/tab-bar';

const element = document.getElementById('stats-tabs');

if (element) {
  const bar = new MDCTabBar(element);

  element.addEventListener('MDCTabBar:activated', function(data) {
    const labels = element.querySelectorAll('.mdc-tab .mdc-tab__text-label');
    const table = document.querySelector('.crm-stats-table');
    const label = labels[data.detail.index];

    if (label && label.getAttribute('data-path')) {
      table.style.visibility = "hidden";
      window.location.pathname = label.getAttribute('data-path');
    }
  });
}
