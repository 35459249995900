import {MDCDrawer} from '@material/drawer';

const icon = document.getElementById('help-action-icon');

if (icon) {
  const element = document.getElementById('crm-help-drawer');
  const content = element.querySelector('.crm-help-content');
  const drawer = new MDCDrawer(element);

  icon.addEventListener('click', function() {
    if (content.hasChildNodes()) {
      drawer.open = true;
    } else {
      window.location.pathname = icon.getAttribute('data-path');
    }
  });
}
