import {MDCMenu, Corner} from '@material/menu';

const element = document.getElementById('profile-action-menu');
var icon = document.getElementById('profile-action-icon');

if (element && icon) {
  const menu = new MDCMenu(element);
  menu.setAnchorCorner(Corner.BOTTOM_START);

  icon.addEventListener('click', function() {
    menu.open = !menu.open;
  });
}
