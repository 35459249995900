import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'status', 'closedAfter', 'user' ]

  connect() {
    this.toggleClosedAfter(this.statusTarget.querySelector('input').value)
  }

  statusToggle({ detail: { value } }) {
    this.toggleClosedAfter(value)
  }

  toggleClosedAfter(status) {
    if (status == 'closed') {
      this.closedAfterTarget.parentElement.classList.remove('hidden')
      this.closedAfterTarget.disabled = false
    } else {
      this.closedAfterTarget.parentElement.classList.add('hidden')
      this.closedAfterTarget.disabled = true
    }
  }

  groupToggle({ detail: { value } }) {
    if (!this.hasUserTarget) { return }

    if (value == this.userTarget.dataset.group) {
      this.userTarget.mdcselect.disabled = false
      this.userTarget.classList.remove('hidden')
    } else {
      this.userTarget.mdcselect.disabled = true
      this.userTarget.classList.add('hidden')
    }
  }

}
