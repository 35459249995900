import {MDCSnackbar} from '@material/snackbar';

const sb = new MDCSnackbar(document.getElementById('crm-snackbar'));

function snackbar(text, timeout) {
  sb.labelText = text;
  if (timeout) {
    sb.timeoutMs = timeout;
  }
  sb.open();
}

export { snackbar };
