import Rails from '@rails/ujs';
import {snackbar} from 'snackbar-util';
import {CrmAttachments} from './crm-attachments';
import {listenCopyPaste} from './message-copy-paste';
import {MDCSelect} from '@material/select';

function renderCompose(form, attachments, email) {
  const errors = form.querySelector('[role="errors"]');
  const defect = form.querySelector('[role="defect"]');
  const language = form.querySelector('[role="language"]').mdcselect
  const template = form.querySelector('[role="template"]');
  const templateName = form.querySelector('[role="template-name"]');

  // show/hide buttons and selects
  if (email.persisted) {
    form.querySelectorAll('[role~="new-record"]').forEach(function(el){
      el.classList.add('hidden');
    });
    form.querySelectorAll('[role~="persisted"]').forEach(function(el){
      setEmailID(el, email.id);
      el.classList.remove('hidden');
    });
  } else {
    form.querySelectorAll('[role~="persisted"]').forEach(function(el){
      el.classList.add('hidden');
    });
    form.querySelectorAll('[role~="new-record"]').forEach(function(el){
      el.classList.remove('hidden');
    });
  }

  // errors
  if (email.errors) {
    errors.innerText = email.errors.join(', ');
    errors.classList.remove('hidden');
  } else {
    errors.innerText = '';
    errors.classList.add('hidden');
  }
  // defect
  if (email.defect) {
    defect.innerText = email.defect;
    defect.classList.remove('hidden');
  } else {
    defect.innerText = '';
    defect.classList.add('hidden');
  }
  // author
  if (email.persisted) {
    form.querySelector('[role="author"]').innerText = `${email.author} saved at ${email.updated_time}`
  }
  // template
  if (email.persisted) {
    template.disabled = true;
    language.disabled = true;
    templateName.disabled = true;
  } else {
    template.value = email.template_id;
    templateName.value = email.template_name;
    language.value = email.language;
    template.disabled = false;
    language.disabled = false;
    templateName.disabled = false;
  }

  // email attributes
  form.querySelector('[role="email-subject"]').value = email.subject;
  form.querySelector('[role="email-from"]').value = email.from;
  form.querySelector('[role="email-to"]').value = email.to;
  form.querySelector('[role="email-cc"]').value = email.cc;
  form.querySelector('[role="email-bcc"]').value = email.bcc;
  form.querySelector('[role="email-body"]').value = email.body;

  // attachments
  if (email.persisted) {
    attachments.fetch();
  }

  Rails.fire(form, 'emailcomposed', email)
}

function setEmailID(el, id) {
  ['formaction', 'href', 'data-path'].some(name => {
    const attr = el.getAttribute(name);
    if (attr) {
      el.setAttribute(name, attr.replace(/emails\/\d+/, `emails/${id}`));
    }
    return attr;
  });
}

const compose = document.getElementById('issue-email-compose');
if (compose){
  const form = compose.querySelector('form');
  const messages =  document.getElementById('messages');
  const buildURL = compose.dataset.buildUrl;

  const attachments = new CrmAttachments(compose);

  function buildCompose() {
    Rails.ajax({
      url: buildURL, type: 'GET',
      success: response => renderCompose(form, attachments, response),
      error: response => snackbar( response )
    });
  }

  document.addEventListener("DOMContentLoaded", function(){
    buildCompose();
  });

  form.querySelectorAll('input[type="text"]').forEach(function(input) {
    input.addEventListener('keypress', function(event) {
      if (event.key == 'Enter') {
        event.preventDefault()
      }
    })
  });

  form.addEventListener('ajax:before', function(event) {
    form.querySelectorAll('button[type="submit"]').forEach(function(btn) {
      if (!btn.dataset.waiting && btn.offsetParent === null) {
        btn.dataset.waiting = 'on'
        btn.disabled = true
      }
    });
  });
  form.addEventListener('ajax:complete', function(event) {
    form.querySelectorAll('button[type="submit"]').forEach(function(btn) {
      if (btn.dataset.waiting) {
        delete btn.dataset.waiting
        btn.disabled = false
      }
    });
  });

  form.addEventListener('ajax:success', function(event) {
    const { target, detail } = event;
    if (form != target) return; // the form contains other ajax elements

    const response = detail[0];
    const xhr = detail[2];

    if (xhr.status == 202) {
      messages.appendChild(response.querySelector('.timeline-item'));
      compose.classList.add('hidden');
    } else if (response.preview) {
      // Handled in stimulus
    } else {
      renderCompose(form, attachments, response);
    }
  });

  form.addEventListener('ajax:error', function(event) {
    const { target, detail } = event;
    if (form != target) return;
    snackbar( detail[0], 10000 );
  });

  const template = form.querySelector('[role="template"]');
  const templateName = form.querySelector('[role="template-name"]');

  templateName.addEventListener('change', function(event) {
    const name = event.target.value
    const options = event.target.list.querySelectorAll('option')
    const selected = Array.prototype.find.call(options, function (o) { return o.value == name })
    if (selected) {
      templateName.setCustomValidity('')
      templateName.reportValidity()
      templateName.parentElement.classList.remove('mdc-text-field--invalid')
      template.value = selected.dataset.id
      Rails.fire(form, 'submit')
    } else {
      templateName.setCustomValidity("Expecting email template name")
      templateName.reportValidity()
    }
  });

  const language = form.querySelector('[role="language"]').mdcselect
  language.listen('MDCSelect:change', function(e) {
    Rails.fire(form, 'submit');
  });

  listenCopyPaste(form.querySelector('textarea[role="email-body"]'));
}
