import { Controller } from '@hotwired/stimulus'
import { MDCDialog } from '@material/dialog'
import { snackbar } from 'snackbar-util'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'dialog', 'form', 'body', 'subject', 'defect' ]

  connect(){
    this.dialog = new MDCDialog(this.dialogTarget)
  }

  open(event){
    const button = event.currentTarget
    const self = this
    const formData = new FormData()
    const inputNames = ['from', 'to', 'cc', 'bcc', 'subject', 'body']

    inputNames.forEach((name) => {
      const input = this.formTarget.querySelector(`[name="email[${name}]"]`)
      formData.append(input.name, input.value)
    })

    Rails.ajax({
      url: self.data.get('url'),
      type: 'POST',
      data: formData,
      success: response => self.render(response),
      error: response => snackbar(response),
      beforeSend: () => {
        button.disabled = true
        return true // to continue sending
      },
      complete: () => {
        button.disabled = false
      }
    })
  }

  render(email){
    this.subjectTarget.innerText = email.subject
    this.bodyTarget.innerHTML = email.preview

    if (email.defect) {
      this.defectTarget.innerText = email.defect
      this.defectTarget.classList.remove('hidden')
    } else {
      this.defectTarget.innerText = ''
      this.defectTarget.classList.add('hidden')
    }

    ['from', 'to', 'cc', 'bcc'].forEach((name) => {
      const input = this.formTarget.querySelector(`[name="email[${name}]"]`)

      if (email[name]) {
        this.dialogTarget.querySelector(`dd[role="${name}"]`).innerText = email[name]
        this.dialogTarget.querySelectorAll(`[role="${name}"]`).forEach(el => el.classList.remove('hidden'))
      } else {
        this.dialogTarget.querySelectorAll(`[role="${name}"]`).forEach(el => el.classList.add('hidden'))
      }
    })

    this.dialog.open()
  }
}
