import {MDCTabBar} from '@material/tab-bar';

function crmActivateEmailPreviewTabs() {
  [].forEach.call(document.querySelectorAll('.crm-email-preview-tabbar'), function(tabBar) {
    const mdcTabBar = new MDCTabBar(tabBar);
    tabBar.addEventListener('MDCTabBar:activated', function(data) {
      var id = tabBar.getAttribute('data-target'),
          target = document.getElementById(id),
          index = data.detail.index;
      target.setAttribute('data-active', index);
    });
  });
}

crmActivateEmailPreviewTabs();

export { crmActivateEmailPreviewTabs };
