import Rails from '@rails/ujs';
import {snackbar} from 'snackbar-util';

const form = document.getElementById('disable-coaching-form');
if (form) {
  const input = form.querySelector('.mdc-checkbox');
  input.addEventListener('change', function(event) {
    Rails.fire(form, 'submit');
  });

  form.addEventListener('ajax:success', function(event) {
    const response = event.detail[0];
    snackbar(`Coaching is ${response.disable_coaching ? 'disabled' : 'enabled'} for this student`);
  });

  form.addEventListener('ajax:error', function(event) {
    snackbar('Error prohibited updating the coaching status');
  });
}
