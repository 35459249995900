import { Controller } from '@hotwired/stimulus'
import { MDCMenuSurface, Corner } from '@material/menu-surface'

export default class extends Controller {
  static targets = [ 'surface' ]

  connect() {
    this.menuSurface = new MDCMenuSurface(this.surfaceTarget)
    this.menuSurface.setAnchorCorner(Corner.TOP_RIGHT)
    this.menuSurface.setAnchorMargin({right: 200, top: -12})
  }

  exposeSurface() {
    this.menuSurface.open()
  }
}
