/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant directory structure and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()

import 'auto-init';
import 'crm-alert';
import 'crm-clickable';
import 'crm-fold';
import 'disable-coaching-form';
import 'email-divert';
import 'email-edit';
import 'email-form';
import 'email-format-tab';
import 'email-preview-tabs';
import 'form-field-init';
import 'help-drawer';
import 'issue-assign';
import 'issue-comment';
import 'issue-email';
import 'issue-link';
import 'issue-response';
import 'learner-addresses';
import 'learner-nickname-form';
import 'logbook';
import 'memo';
import 'note';
import 'notifications';
import 'outbox-filter';
import 'phrase-transfer';
import 'profile-menu';
import 'scoped-style';
import 'snackbar-flash';
import 'stats';
import 'suppression-logs';
import 'suppressions';
import 'template-preview';
import 'timeline-collapse';
import 'variants-form';

// stimulus
import 'controllers';

// Images
require.context('../images', true)

// Style
import 'css';
