const className = 'unsaved';
const message = 'Do you want to leave the page? Data you have entered may not be saved.';

function setupUnloadConfirmation() {
  for (var i = 0; i < arguments.length; i++) {
    arguments[i].addEventListener('input', function(event){
      markAsUnsaved(event.target);
    });
    arguments[i].addEventListener('change', function(event){
      markAsUnsaved(event.target);
    });
  }

  window.addEventListener('beforeunload', function (event) {
    if (document.getElementsByClassName(className).length) {
      event.preventDefault();
      event.returnValue = message;
    }
  });
}

function markAsUnsaved(element) {
  element.classList.add(className);
}

function markAsSaved(element) {
  element.classList.remove(className);
}

export { setupUnloadConfirmation, markAsUnsaved, markAsSaved };
