import {MDCDialog} from '@material/dialog';
import {snackbar} from 'snackbar-util';

const compose = document.getElementById('compose-note');
const notes = document.getElementById('notes');
const btn = document.getElementById('add-note-button');

if (compose && notes && btn) {
  const dialog = new MDCDialog(compose);
  const form = compose.querySelector('form');

  function updateWith(detail) {
    const response = detail[0];
    const html = response.querySelector('#notes');
    notes.innerHTML = html.innerHTML;
    form.querySelector('textarea').value = '';
  }

  // add note button
  btn.addEventListener('click', function(event) {
    dialog.open();
  });
  // delete note button
  notes.addEventListener('ajax:success', function(event) {
    updateWith(event.detail);
  });
  notes.addEventListener('ajax:error', function(event) {
    snackbar(event.detail[0]);
  });

  form.addEventListener('ajax:success', function(event) {
    dialog.close();
    snackbar('Note saved');
    updateWith(event.detail);
  });

  form.addEventListener('ajax:error', function(event) {
    snackbar(event.detail[0]);
  });
}
