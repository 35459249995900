import Rails from '@rails/ujs';
import {MDCDialog} from '@material/dialog';
import {MDCTextFieldIcon} from '@material/textfield/icon';
import {snackbar} from 'snackbar-util';

const form = document.getElementById('learner-nickname-form');
if (form) {
  const input = form.querySelector('.mdc-text-field');
  input.addEventListener('MDCTextField:icon', function(event) {
    Rails.fire(form, 'submit');
  });

  form.addEventListener('ajax:success', function(event) {
    const response = event.detail[0];
    snackbar( `Updated nickname to ${response.mentoring_nickname}` );
  });

  form.addEventListener('ajax:error', function(event) {
    snackbar('Error prohibited updating the nickname');
  });
}
