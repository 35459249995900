import {MDCDrawer} from '@material/drawer';
import {MDCTabBar} from '@material/tab-bar';
import {MDCFormField} from '@material/form-field';
import {MDCRadio} from '@material/radio';
import Rails from '@rails/ujs';
import {snackbar} from 'snackbar-util';
import {crmActivateEmailPreviewTabs} from 'email-preview-tabs';

const learnerFilterDrawerElement = document.getElementById('preview-learner-filter-drawer');
if (learnerFilterDrawerElement) {
  const learnerFilterDrawer = new MDCDrawer(learnerFilterDrawerElement);
  const learnerFilterIcon = document.getElementById('crm-learner-filter-icon');
  learnerFilterIcon.addEventListener('click', function() {
    learnerFilterDrawer.open = true;
  });

  const learnerFilterForm = learnerFilterDrawerElement.querySelector('form');
  var learnersList = document.getElementById('learners-list');
  var learnersListProgressbar = document.getElementById('learners-list-progressbar');
  var learnersListHint = learnersList.querySelector('.crm-empty-side-card-hint');
  var previewForm = document.getElementById('preview-form');
  var previewTarget = document.getElementById('preview-email');
  var previewProgressbar = document.getElementById('preview-progressbar');
  var previewAlert = document.getElementById('preview-alert');
  var previewButton = document.getElementById('preview-button');

  const pageInput = learnerFilterForm.querySelector('input[name="page"]');
  const pagination = document.getElementById('learner-pagination');

  if (pagination && pageInput) {
    const btnNext = pagination.querySelector('[role="next"]');
    const btnPrev = pagination.querySelector('[role="prev"]');
    const pageNum = pagination.querySelector('[role="page"]');
    btnNext.addEventListener('click', function(event) {
      const page = parseInt(pageInput.value);
      pageInput.value = page + 1;
      pagination.classList.add('hidden');
      pageNum.innerText = pageInput.value;
      Rails.fire(learnerFilterForm, 'submit');

    });
    btnPrev.addEventListener('click', function(event) {
      const page = parseInt(pageInput.value);
      if (page == 1) return;
      pageInput.value = page - 1;
      pagination.classList.add('hidden');
      pageNum.innerText = pageInput.value;
      Rails.fire(learnerFilterForm, 'submit');
    });
  }

  previewForm.addEventListener('ajax:success', function(event) {
    const response = event.detail[0];

    if (response['message']) {
      snackbar(response['message']);
      return;
    }

    const previewBody = response.querySelector('.mdc-card');
    [].forEach.call(previewTarget.querySelectorAll('.mdc-card'), function(card) {
      previewTarget.removeChild(card);
    });
    previewTarget.appendChild(previewBody);

    crmActivateEmailPreviewTabs();
  });

  previewForm.addEventListener('ajax:error', function(event) {
    const response = event.detail[0];

    if (response['message']) {
      snackbar(response['message']);
      return;
    }

    [].forEach.call(previewTarget.querySelectorAll('.mdc-card'), function(card) {
      previewTarget.removeChild(card);
    });

    if (previewAlert.classList.contains('hide')) {
      previewAlert.classList.remove('hide');
    }
  });
  previewForm.addEventListener('ajax:before', function(event) {
    if (!previewAlert.classList.contains('hide')) {
      previewAlert.classList.add('hide');
    }
    if (previewProgressbar.classList.contains('mdc-linear-progress--closed')) {
      previewProgressbar.classList.remove('mdc-linear-progress--closed');
    }
  });
  previewForm.addEventListener('ajax:complete', function(event) {
    if (!previewProgressbar.classList.contains('mdc-linear-progress--closed')) {
      previewProgressbar.classList.add('mdc-linear-progress--closed');
    }
  });

  learnerFilterForm.addEventListener('ajax:before', function(event) {
    if (!learnersListHint.classList.contains('hide')) {
      learnersListHint.classList.add('hide');
    }
    if (pagination) { pagination.classList.add('hidden'); }
    [].forEach.call(learnersList.querySelectorAll('.mdc-form-field'), function(item) {
      learnersList.removeChild(item);
    });
    learnerFilterDrawer.open = false;
    if (learnersListProgressbar.classList.contains('mdc-linear-progress--closed')) {
      learnersListProgressbar.classList.remove('mdc-linear-progress--closed');
    }
  });
  learnerFilterForm.addEventListener('ajax:complete', function(event) {
    if (!learnersListProgressbar.classList.contains('mdc-linear-progress--closed')) {
      learnersListProgressbar.classList.add('mdc-linear-progress--closed');
    }
  });
  learnerFilterForm.addEventListener('ajax:success', function(event) {
    const template = document.getElementById('learners-list-item-template');
    const learners = event.detail[0];

    learners.forEach(function(learner){
      var field = document.importNode(template.content, true);
      var input = field.querySelector('input');
      var id = 'learner-' + learner.id;

      input.id = id;
      input.value = learner.id;
      input.setAttribute('data-id', learner.id);
      input.setAttribute('data-type', learner.type);

      var link = field.querySelector('a');
      link.setAttribute('href', learner.path);

      var label = field.querySelector('label');
      label.setAttribute('for', id);
      field.querySelector('.crm-learner-list-name').textContent = learner.first_name + ' ' + learner.last_name;
      var emailogin = learner.email;
      if (learner.login) { emailogin += (' / ' + learner.login); }
      field.querySelector('.crm-learner-list-courses').textContent = emailogin;
      var formField = new MDCFormField(field);
      formField.input = new MDCRadio(field.querySelector('.mdc-radio'));
      learnersList.appendChild(field);
    });
    [].forEach.call(learnersList.querySelectorAll('input[type="radio"]'), function(input) {
      input.addEventListener('change', function(event) {
        if (input.checked) {
          previewForm.querySelector('input[name="learner_id"]').value = input.value;
          previewForm.querySelector('input[name="learner_type"]').value = input.dataset.type;
          if (previewButton) {
            if (previewButton.disabled) { previewButton.disabled = false; }
            previewButton.click();
          } else {
            Rails.fire(previewForm, 'submit');
          }
        }
      });
    });
    if (pagination && learners.length >= 20) {
      pagination.classList.remove('hidden');
    }
  });
  learnerFilterForm.addEventListener('ajax:error', function(event) {
    if (learnersListHint.classList.contains('hide')) {
      learnersListHint.classList.remove('hide');
    }
    learnersListHint.textContent = "No learner found with given conditions";
  });
}
