import { Controller } from '@hotwired/stimulus'
import { MDCSelect } from '@material/select'

export default class extends Controller {
  static targets = [ 'select' ]

  connect() {
    this.select = new MDCSelect(this.selectTarget)
  }

  reload({detail: {value}}) {
    window.location.href = value
  }
}
