import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  display() {
    const SCROLL = 2000

    if (document.body.scrollTop > SCROLL ||
        document.documentElement.scrollTop > SCROLL) {
      this.element.style.display = "block"
    } else {
      this.element.style.display = "none"
    }
  }

  scroll() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox
  }
}
