import {MDCFormField} from '@material/form-field';
import {MDCCheckbox} from '@material/checkbox';
import {MDCRadio} from '@material/radio';

[].forEach.call(document.querySelectorAll('.mdc-form-field'), function(element) {
  var child = element.querySelector('.mdc-checkbox, .mdc-radio');
  if (child) {
    if (child.classList.contains('mdc-checkbox')) {
      element.input = new MDCCheckbox(child);
    } else if (child.classList.contains('mdc-radio')) {
      var field = new MDCFormField(element);
      field.input = new MDCRadio(child);
    }
  }
});
