import Rails from '@rails/ujs';
import {snackbar} from 'snackbar-util';

const selectors = {
  BUTTON: '.crm-attachments__button',
  SECTION: '.crm-attachments',
  LIST: '.attachments'
};

const messages = {
  ERROR: '<span class="crm-red mdc-typography--caption">Error during attachment processing</span>'
};

class CrmAttachments {
  constructor(container) {
    this.button = container.querySelector(selectors.BUTTON);
    this.section = container.querySelector(selectors.SECTION);
    this.input = this.section.querySelector('input[type="file"]');
    this.progress = this.section.querySelector('[role="progressbar"]');

    this.listen();
  }

  listen() {
    this.button.addEventListener('click', () => this.input.click());

    this.input.addEventListener('change', () => {
      let self = this;
      const data = new FormData();
      Array.from(this.input.files).forEach(file => data.append(this.input.name, file));
      Rails.ajax({
        url: this.input.dataset.path,
        type: 'POST',
        data: data,
        success: () => self.fetch(),
        error: response => snackbar(response),
        beforeSend: () => {
          self.progress.classList.remove('hidden');
          return true; // to continue sending
        },
        complete: () => {
          self.progress.classList.add('hidden');
          self.input.value = null;
        }
      });
    });
  }

  fetch() {
    const self = this;
    const list = this.section.querySelector(selectors.LIST);
    Rails.ajax({
      type: 'GET',
      url: this.section.dataset.path,
      success: function(response) {
        const fresh = response.querySelector(selectors.LIST);
        self.section.replaceChild(fresh, list);
        self.section.querySelectorAll('[data-method="delete"]').forEach(
          button => button.addEventListener('ajax:success', () => self.fetch())
        );
      },
      error: function(response) {
        list.innerHTML = messages.ERROR;
      }
    });
  }

  emit(evtType, evtData, shouldBubble = false) {
    let evt;
    if (typeof CustomEvent === 'function') {
      evt = new CustomEvent(evtType, { detail: evtData, bubbles: shouldBubble });
    } else {
      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(evtType, shouldBubble, false, evtData);
    }

    this.section.dispatchEvent(evt);
  }
}

export { CrmAttachments };
