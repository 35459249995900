import {MDCChipSet} from '@material/chips';

const element = document.getElementById('logbook-filter');

if (element) {
  const chipSet = new MDCChipSet(element)
  const entries = document.getElementById('logbook-entries')

  chipSet.listen('MDCChipSet:selection', function(event) {
    const detail = event.detail
    const items = Array.from(entries.getElementsByClassName(detail.chipID))
    if (detail.isSelected) {
      items.forEach(i => i.classList.remove('hidden'))
    } else {
      items.forEach(i => i.classList.add('hidden'))
    }
  })

  const selectedID = element.dataset.selected

  if (selectedID == 'all') {
    Array.from(element.getElementsByClassName('mdc-evolution-chip')).forEach((chip, idx) => {
      chipSet.setChipSelected(idx, 1, true)
    })
  } else {
    const idx = chipSet.getChipIndexByID(selectedID)
    chipSet.setChipSelected(idx, 1, true)
  }

}
