import {MDCDialog} from '@material/dialog';
import {snackbar} from 'snackbar-util';

const compose = document.getElementById('compose-memo');
const memos = document.getElementById('memos');

if (compose && memos) {
  const btn = document.getElementById('add-memo-button');
  const dialog = new MDCDialog(compose);
  const form = compose.querySelector('form');

  function updateWith(detail) {
    const response = detail[0];
    const html = response.querySelector('#memos');
    memos.innerHTML = html.innerHTML;
    form.querySelector('textarea').value = '';
  }

  // add memo button
  btn.addEventListener('click', function(event) {
    dialog.open();
  });
  // delete memo button
  memos.addEventListener('ajax:success', function(event) {
    updateWith(event.detail);
  });
  memos.addEventListener('ajax:error', function(event) {
    snackbar(event.detail[0]);
  });

  form.addEventListener('ajax:success', function(event) {
    dialog.close();
    snackbar('Memo saved');
    updateWith(event.detail);
  });

  form.addEventListener('ajax:error', function(event) {
    snackbar(event.detail[0]);
  });
}
