const form = document.getElementById('outbox-filter');
if (form) {
  const source = form.querySelector('select[name="search[source]"]');
  const category = form.querySelector('select[name="search[category]"]');
  if (source && category) {
    function sourceTogglesCategory(value) {
      if (value == 'rems' || value == 'rex') {
        category.parentElement.mdcselect.disabled = false;
      } else {
        category.parentElement.mdcselect.disabled = true;
      }
    }

    source.addEventListener('change', function(event) {
      sourceTogglesCategory(event.target.value);
    });
    sourceTogglesCategory(source.value);
  }
}
