import { Controller } from '@hotwired/stimulus'
import { MDCTextField } from '@material/textfield'
import { MDCDialog } from '@material/dialog'
import { snackbar } from 'snackbar-util'

export default class extends Controller {
  static targets = [ 'dialog', 'list', 'input' ]

  connect() {
    MDCTextField.attachTo(this.inputTarget)

    const dialog = new MDCDialog(this.dialogTarget)
    this.dialog = dialog

    document.getElementById('relate-ticket-button').addEventListener('click', function(event) {
      dialog.open()
    })

    this.listTarget.classList.toggle('hide', this.listTarget.querySelectorAll('a').length == 0)
  }

  updateList(event) {
    const response = event.detail[0].querySelector('body')
    this.dialog.close()
    this.inputTarget.querySelector('input').value = ''

    this.listTarget.innerHTML = response.innerHTML
    this.listTarget.classList.toggle('hide', this.listTarget.querySelectorAll('a').length == 0)
  }

  errored(event) {
    snackbar(event.detail[0])
  }
}
