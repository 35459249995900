import {MDCDialog} from '@material/dialog';

const dialogElement = document.getElementById('email-divert-dialog');
const button = document.getElementById('email-divert-button');

if (button && dialogElement) {
  const dialog = new MDCDialog(dialogElement);

  button.addEventListener('click', function(event) {
    dialog.open();
  });
}
