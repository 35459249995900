import Rails from '@rails/ujs';
import {MDCTextFieldIcon} from '@material/textfield/icon';
import {snackbar} from 'snackbar-util';

const add = 'add_circle';
const del = 'remove_circle';
const upd = 'check_circle';

document.querySelectorAll('.learner-address-form').forEach(function(form){
  const field = form.querySelector('.mdc-text-field');
  const input = field.querySelector('input');
  const method = form.querySelector('input[name="_method"]');
  const icon = form.querySelector('.mdc-text-field__icon');

  field.addEventListener('MDCTextField:icon', function(event) {
    if (icon.innerText == del) {
      method.value = 'delete';
    } else if (icon.innerText == add) {
      method.value = 'put';
    } else if (icon.innerText == upd) {
      method.value = 'patch';
    }
    Rails.fire(form, 'submit');
  });

  input.addEventListener('input', function(event) {
    if (icon.innerText == del) {
      icon.classList.add('mdc-theme--secondary');
      icon.innerText = upd;
    } else if (icon.innerText == add) {
      icon.classList.add('mdc-theme--secondary');
    }
  });

  form.addEventListener('ajax:success', function(event) {
    const response = event.detail[0];
    icon.classList.remove('mdc-theme--secondary');
    if (icon.innerText == add) {
      icon.innerText = del;
      form.action = form.action + '/' + response.id;
      snackbar(`Email address ${response.email} added`);
    } else if (icon.innerText == upd) {
      icon.innerText = del;
      snackbar(`Email address updated to ${response.email}`);
    } else if (icon.innerText == del) {
      form.remove();
      snackbar(`Email address ${response.email} deleted`);
    }
  });

  form.addEventListener('ajax:error', function(event) {
    snackbar(event.detail[0]);
  });
});
