import { Controller } from '@hotwired/stimulus'
import { MDCMenuSurface, Corner } from '@material/menu-surface'
import { MDCChipSet } from '@material/chips'
import Rails from '@rails/ujs'
import { snackbar } from 'snackbar-util'

export default class extends Controller {
  static targets = [ 'surface', 'chipset', 'chip', 'form', 'add', 'input' ]

  connect() {
    this.addTarget.classList.toggle('hidden', this.hasChipTarget)

    this.menuSurface = new MDCMenuSurface(this.surfaceTarget)
    this.menuSurface.setAnchorCorner(Corner.TOP_LEFT)
    this.menuSurface.setAnchorMargin({left: -150, top: -8})

    this.chipSet = new MDCChipSet(this.chipsetTarget)
  }

  exposeSurface() {
    this.menuSurface.open()
  }

  save() {
    if (this.formTarget.querySelectorAll('input[type="checkbox"]:checked').length) {
      this.addTarget.classList.add('hidden')
    }
    Rails.fire(this.formTarget, 'submit')
  }

  updateChips(event) {
    const categories = event.detail[0]

    this.addTarget.classList.toggle('hidden', categories.length)

    this.chipTargets.forEach((chip) => {
      const id = parseInt(chip.dataset.id)
      chip.classList.toggle('hidden', !categories.find(c => c.id == id))
    })

    categories.forEach((category) => {
      const id = category.id.toString()
      var shown = false

      this.chipTargets.forEach((chip) => {
        if (chip.dataset.id == id) {
          chip.classList.remove('hidden')
          shown = true
        }
      })

      if (!shown) {
        const text = document.createElement('span')
        text.classList.add('mdc-evolution-chip__text-label')
        text.appendChild(document.createTextNode(category.name))

        const ripple = document.createElement('span')
        ripple.classList.add('mdc-evolution-chip__ripple', 'mdc-evolution-chip__ripple--primary')

        const button = document.createElement('button')
        button.classList.add('mdc-evolution-chip__action', 'mdc-evolution-chip__action--primary')
        button.setAttribute('type', 'button')
        button.setAttribute('tabindex', '0')
        button.appendChild(ripple)
        button.appendChild(text)

        const cell = document.createElement('span')
        cell.classList.add('mdc-evolution-chip__cell', 'mdc-evolution-chip__cell--primary')
        cell.setAttribute('role', 'gridcell')
        cell.appendChild(button)

        const chip = document.createElement('span')
        chip.classList.add('mdc-evolution-chip', 'mdc-evolution-chip--dense', 'mr1')
        chip.dataset.id = id
        chip.dataset.issueCategorizeTarget = 'chip'
        chip.setAttribute('tabindex', '0')
        chip.setAttribute('role', 'row')
        chip.setAttribute('id', 'category-chip-'+id)
        chip.setAttribute('data-action', 'click->issue-categorize#exposeSurface')
        chip.appendChild(cell)

        const chips = this.chipsetTarget.querySelector('.mdc-evolution-chip-set__chips')
        chips.appendChild(chip)
      }
    })
  }

  showError(event) {
    this.addTarget.classList.remove('hidden')
    snackbar(event.detail[0])
  }
}
