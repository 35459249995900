import {MDCDialog} from '@material/dialog';
import {snackbar} from 'snackbar-util';

const element = document.getElementById('write-comment');
const button = document.getElementById('write-comment-button');
const messages = document.getElementById('messages');

if (element && button && messages) {
  const dialog = new MDCDialog(element);
  const form = element.querySelector('form');
  const textarea = element.querySelector('textarea');

  button.addEventListener('click', function(event){
    dialog.open();
  });

  form.addEventListener('ajax:success', function(event) {
    dialog.close();
    textarea.value = '';
    messages.appendChild(event.detail[0].querySelector('.timeline-item'));
  });

  form.addEventListener('ajax:error', function(event) {
    snackbar( event.detail[0] );
  });

  messages.addEventListener('ajax:success', function(event) {
    const response = event.detail[0];
    if (response.action == 'destroy') {
      document.getElementById(`comment-${response.comment.id}`).remove();
    }
  });
}
