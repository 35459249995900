import { Controller } from '@hotwired/stimulus'
import { MDCRipple } from '@material/ripple';

export default class extends Controller {
  static targets = [ 'nav' ]

  connect() {
    this.navTargets.forEach((nav) => {
      MDCRipple.attachTo(nav)
    })
  }
}
