import {MDCRipple} from '@material/ripple';
import {MDCList} from '@material/list';
import {MDCMenu, Corner} from '@material/menu';

const pollInterval = 60000; // 1 minute
const initialPollAfter = 10000;
const actionIcon = document.getElementById('notification-action-icon');
const actionBtn = document.getElementById('notification-action-menu');

function itemClicked(item) {
  var url = item.getAttribute('data-url');
  if (url.startsWith('http')) {
    window.location.assign(url);
  } else {
    window.location.pathname = url;
  }
};

const menuElement = document.getElementById('crm-notifications-menu');
if (menuElement) {
  const menu = new MDCList(menuElement);
  setTimeout(refreshNotifications, initialPollAfter, menuElement);
}

const deleteBtn = document.getElementById('notifications-delete-link');

if (actionBtn && actionIcon) {
  const actionMenu = new MDCMenu(actionBtn);
  actionMenu.setAnchorCorner(Corner.BOTTOM_START);
  actionIcon.addEventListener('click', function() {
    if (menuElement.childElementCount > 0) {
      actionMenu.open = !actionMenu.open;
    }
  });

  if (deleteBtn) {
    deleteBtn.addEventListener('ajax:success', function(event) {
      refreshNotifications(menuElement);
    });
    deleteBtn.addEventListener('click', function() {
      actionMenu.open = false;
      [].forEach.call(menuElement.querySelectorAll('li.mdc-deprecated-list-item'), function(item) {
        item.remove();
      });
    });
  }
}

function refreshNotifications(menu){
  var request = new XMLHttpRequest();
  request.open('GET', '/coaching/notifications.json', true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 300) {
      var data = JSON.parse(request.responseText);
      var max = Math.max.apply(Math, data.map(function(n) { return n.id; }));
      if (max) {
        var deleteHref = deleteBtn.getAttribute('href');
        deleteBtn.setAttribute('href', deleteHref.replace(/\d+$/, max));
      }
      [].forEach.call(data, function(n) {
        var li = document.getElementById('n-' + n.id);
        if (!li) {
          var li = document.createElement("li");
          li.classList.add('mdc-deprecated-list-item');
          li.setAttribute('data-url', n.url);
          li.id = 'n-' + n.id;
          var graphic = document.createElement("span");
          graphic.classList.add('mdc-deprecated-list-item__graphic');
          var i =  document.createElement('i');
          i.classList.add('material-icons');
          i.appendChild(document.createTextNode(n.icon));
          graphic.appendChild(i);
          li.appendChild(graphic);
          var itemText = document.createElement("span");
          itemText.classList.add('mdc-deprecated-list-item__text');
          var primaryText = document.createElement("span");
          primaryText.classList.add('mdc-deprecated-list-item__primary-text');
          primaryText.appendChild(document.createTextNode(n.title));
          var secondaryText = document.createElement("span");
          secondaryText.classList.add('mdc-deprecated-list-item__secondary-text');
          secondaryText.appendChild(document.createTextNode(n.message));
          itemText.appendChild(primaryText);
          itemText.appendChild(secondaryText);
          li.appendChild(itemText);
          var meta = document.createElement("span");
          meta.classList.add('mdc-deprecated-list-item__meta');
          meta.appendChild(document.createTextNode(n.distance));
          li.appendChild(meta);
          menu.appendChild(li);
          MDCRipple.attachTo(li);
          li.addEventListener('click', function(e) {
            itemClicked(li);
          });
        }
      });

      actionIcon.textContent = menu.childElementCount > 0 ? 'notifications_active' : 'notifications_none';
    }
    setTimeout(refreshNotifications, pollInterval, menuElement);
  };
  request.send();
}

// index page
const element = document.getElementById('crm-notifications-list');

if (element) {
  [].forEach.call(element.querySelectorAll('.mdc-deprecated-list-item'), function(item) {
    MDCRipple.attachTo(item);
    item.addEventListener('click', function(e) {
      itemClicked(item);
    });
  });
  const list = new MDCList(element);
  list.singleSelection = true;
}
