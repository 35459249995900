import {MDCSnackbar} from '@material/snackbar';

const element = document.getElementById('crm-flash-snackbar');

if (element) {
  const snackbar = new MDCSnackbar(element);
  if (snackbar.labelText) {
    snackbar.timeoutMs = element.dataset.flash == 'notice' ? 4000 : 8000;
    snackbar.open();
  }
}
