import {MDCMenu, Corner} from '@material/menu';

const assignButton = document.getElementById('assign-button');

if (assignButton){
  const assignMenuEl = document.querySelector("#action-assign"),
        assignMenu = new MDCMenu(assignMenuEl);

  assignMenu.setAnchorCorner(Corner.TOP_LEFT);
  assignMenu.setAnchorMargin({top: 20});
  assignButton.addEventListener('click',function(){
    assignMenu.open = !assignMenu.open;
  });

  window.assignMenu = assignMenu;

  assignMenuEl.addEventListener('MDCMenu:selected', function(event){
    var assignUrl = event.detail.item.getAttribute('formaction');
    var assignForm = document.getElementById('form-issue-assign');
    assignForm.setAttribute('action', assignUrl);
    assignForm.submit();
  });
}
