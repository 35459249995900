import { Controller } from '@hotwired/stimulus'
import { MDCDialog } from '@material/dialog'
import { snackbar } from 'snackbar-util'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'dialog', 'date', 'time',
                     'sendButton', 'cancelButton', 'saveButton', 'scheduledTime' ]

  connect(){
    this.dialog = new MDCDialog(this.dialogTarget)
    this.dialog.autoStackButtons = false
  }

  openDialog(){
    this.dialog.open()
  }

  selectDate(event){
    const date = event.currentTarget.dataset.value

    this.dateTargets.forEach((el) => {
      el.classList.toggle('mdc-button--unelevated', date == el.dataset.value)
    })
  }

  schedule(event){
    const selectedDate = this.dateTargets.find(el => el.classList.contains('mdc-button--unelevated'))
    if (!selectedDate) {
      snackbar("Please select a date")
      return
    }

    const time = this.timeTarget.value
    if (!time) {
      snackbar("Please select a time")
      return
    }

    const self = this
    const formData = new FormData()
    formData.append('scheduled_at', `${selectedDate.dataset.value} ${time}`)

    Rails.ajax({
      url: self.data.get('url'),
      type: 'PUT',
      data: formData,
      success: response => self.scheduleSuccess(response),
      error: response => snackbar(response),
      beforeSend: () => {
        self.saveButtonTarget.disabled = true
        self.cancelButtonTarget.disabled = true
        return true // to continue sending
      },
      complete: () => {
        self.saveButtonTarget.disabled = false
      }
    })
  }

  scheduleSuccess(email){
    this.scheduledTimeTarget.innerText = `Scheduled time ${email.scheduled_time}`
    this.sendButtonTarget.disabled = true
    this.cancelButtonTarget.disabled = false

    if (email.errors) {
      snackbar(email.errors.join(', '))
    } else {
      this.dialog.close()
    }
  }

  unschedule(event){
    const self = this
    Rails.ajax({
      url: self.data.get('url'),
      type: 'DELETE',
      success: response => self.unscheduleSuccess(response),
      error: response => snackbar(response),
      beforeSend: () => {
        self.saveButtonTarget.disabled = true
        self.cancelButtonTarget.disabled = true
        return true // to continue sending
      },
      complete: () => {
        self.saveButtonTarget.disabled = false
      }
    })
  }

  unscheduleSuccess(email) {
    this.dateTargets.forEach((el) => {
      el.classList.remove('mdc-button--unelevated')
    })

    this.timeTarget.value = ''

    this.scheduledTimeTarget.innerText = ''
    this.sendButtonTarget.disabled = false
    this.cancelButtonTarget.disabled = true

    if (email.errors) {
      snackbar(email.errors.join(', '))
    } else {
      this.dialog.close()
    }
  }

  refresh(event){
    const email = event.detail
    // set url
    const url = this.data.get('url')
    if (email.persisted) {
      this.data.set('url', url.replace(/emails\/\d+/, `emails/${email.id}`))
    } else {
      this.data.set('url', url.replace(/emails\/\d+/, `emails/0`))
    }

    // set input controls
    const timestamp = email.scheduled_at
    if (timestamp) {
      // It must be in format: '2020-11-30 08:39'
      const parts = timestamp.split(' ')
      const date = parts[0]
      const time = parts[1]

      this.timeTarget.value = time

      this.dateTargets.forEach((el) => {
        if (date == el.dataset.value) {
          el.classList.add('mdc-button--unelevated')
        }
      })
    }

    // set scheduled time and buttons
    if (email.scheduled) {
      this.scheduledTimeTarget.innerText = `Scheduled time ${email.scheduled_time}`
      this.sendButtonTarget.disabled = true
      this.cancelButtonTarget.disabled = false
    } else {
      this.scheduledTimeTarget.innerText = ''
      this.sendButtonTarget.disabled = false
      this.cancelButtonTarget.disabled = true
    }
  }
}
