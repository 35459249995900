import {MDCTabBar} from '@material/tab-bar';

const element = document.getElementById('suppression-tabs');

if (element) {
  const bar = new MDCTabBar(element);

  element.addEventListener('MDCTabBar:activated', function(data) {
    const labels = element.querySelectorAll('.mdc-tab .mdc-tab__text-label');
    const list = document.querySelector('.crm-suppression-list');
    const progress = document.querySelector('.crm-suppression-progress');
    const label = labels[data.detail.index];

    if (label && label.getAttribute('data-path')) {
      list.style.visibility = 'hidden';
      progress.style.display = 'block';
      window.location.pathname = label.getAttribute('data-path');
    }
  });
}
