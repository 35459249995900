import {MDCDialog} from '@material/dialog';

[].forEach.call(document.querySelectorAll('.crm-checkbox-destroyable'), function(checkbox) {
  checkbox.addEventListener('change', function(event) {
    const destroyId = checkbox.id.replace(/_[a-z]+$/,'__destroy');
    var destroyEl = document.getElementById(destroyId);
    if (destroyEl) {
      destroyEl.value = checkbox.checked ? 'false' : 'true';
    }
  });
});

const vrOidsDialogElement = document.getElementById('vr-oids-dialog');
var vrOrganizationList = document.getElementById('vr-organization-list');
var vrOrganizationRelevanceInput = document.getElementById('vr-organization-relevance');
if (vrOidsDialogElement) {
  function removeVrOrganizationRow(id) {
    const element = document.getElementById(id);
    const input = element.querySelector('input[type="hidden"]');
    const inputId = input.id;
    element.remove();
    if (vrOrganizationList.querySelectorAll('tr').length == 0) {
      const destroyId = inputId.replace(/_[a-z]+$/,'__destroy');
      var destroyEl = document.getElementById(destroyId);
      if (destroyEl) destroyEl.value = 'true';
      if (vrOrganizationRelevanceInput) vrOrganizationRelevanceInput.disabled = true;
    }
  }
  const vrOidsDialog = new MDCDialog(vrOidsDialogElement);
  const vrOidSelect = vrOidsDialogElement.querySelector('select');

  [].forEach.call(vrOrganizationList.querySelectorAll('[role="remove"]'), function(el) {
    el.addEventListener('click', function(event) {
      removeVrOrganizationRow(el.getAttribute('data-target'));
    });
  });

  vrOidsDialog.listen('MDCDialog:closed', function(event) {
    if (event.detail.action != 'accept') return;
    const oid = vrOidSelect.value;
    const domId = 'vr-organization-' + oid;
    const selected = vrOidSelect.options[vrOidSelect.selectedIndex];
    const template = document.getElementById('vr-relevance-organization-tr-template');
    var tr = document.importNode(template.content, true);
    var name = tr.querySelector('.record-name');
    name.innerText = selected.text;
    var input = tr.querySelector('input[type="hidden"]');
    var inputId = input.id;
    input.value = oid;
    var remove = tr.querySelector('[role="remove"]');
    remove.setAttribute('data-target', domId);
    remove.addEventListener('click', function(event) {
      removeVrOrganizationRow(remove.getAttribute('data-target'));
    });
    vrOrganizationList.appendChild(tr);
    vrOrganizationList.lastChild.id = domId;

    if (vrOrganizationList.querySelectorAll('tr').length > 0) {
      const destroyId = inputId.replace(/_[a-z]+$/,'__destroy');
      var destroyEl = document.getElementById(destroyId);
      if (destroyEl) destroyEl.value = 'false';
      if (vrOrganizationRelevanceInput) vrOrganizationRelevanceInput.disabled = false;
    }
  });
  var vrOidPickerActivate = document.getElementById('vr-organization-picker-activate');
  if (vrOidPickerActivate) {
    vrOidPickerActivate.addEventListener('click', function (evt) {
      vrOidsDialog.lastFocusedTarget = evt.target;
      vrOidsDialog.open();
    });
  }
}

const variantVariationsTrigger = document.getElementById('variant-variations-trigger');
const variantVariationsFields = document.getElementById('variant-variations-fields');
if (variantVariationsTrigger && variantVariationsFields) {
  variantVariationsTrigger.addEventListener('change', function(event) {
    if (!variantVariationsTrigger.value) return;
    var fields = variantVariationsFields.querySelector('[lang="' + variantVariationsTrigger.value + '"]');
    var language = fields.querySelector('input[data-role="language"]');
    var destroy = fields.querySelector('input[data-role="destroy"]');
    var textarea = fields.querySelector('textarea');
    var remove = fields.querySelector('[data-role="remove"]');
    if (destroy) destroy.value = 'false';
    language.disabled = false;
    textarea.disabled = false;
    fields.classList.remove('hidden');
  });

  [].forEach.call(variantVariationsFields.querySelectorAll('.crm-variant-variation'), function(fields) {
    var language = fields.querySelector('input[data-role="language"]');
    var destroy = fields.querySelector('input[data-role="destroy"]');
    var textarea = fields.querySelector('textarea');
    var remove = fields.querySelector('[data-role="remove"]');
    remove.addEventListener('click', function(event) {
      if (destroy) destroy.value = 'true';
      language.disabled = true;
      textarea.disabled = true;
      fields.classList.add('hidden');
    });
  });

  variantVariationsFields.addEventListener('click',function(event){
    if (event.target.getAttribute('data-role') == 'delete') {
      if (variantVariationsFields.querySelectorAll('.crm-variant-variation').length == 1) return;
      var parent = event.target.parentNode.parentNode;
      const languageInput = parent.querySelector('input[data-role="language"]');
      const destroyId = languageInput.id.replace(/_[a-z]+$/,'__destroy');
      var destroyInput = document.getElementById(destroyId);
      if (destroyInput) destroyInput.value = 'true';
      parent.remove();
    }
  });
}

const localizationInputTrigger = document.getElementById('variant-localizations-trigger');
const localizationsFields = document.getElementById('variant-localizations-fields');
if (localizationInputTrigger && localizationsFields) {
  localizationInputTrigger.addEventListener('change', function(event) {
    if (!localizationInputTrigger.value) return;
    var fields = localizationsFields.querySelector('[lang="' + localizationInputTrigger.value + '"]');
    var language = fields.querySelector('input[data-role="language"]');
    var destroy = fields.querySelector('input[data-role="destroy"]');
    var textarea = fields.querySelector('textarea');
    var remove = fields.querySelector('[data-role="remove"]');
    if (destroy) destroy.value = 'false';
    language.disabled = false;
    textarea.disabled = false;
    fields.classList.remove('hidden');
  });
  [].forEach.call(localizationsFields.querySelectorAll('.crm-variant-variation'), function(fields) {
    var language = fields.querySelector('input[data-role="language"]');
    var destroy = fields.querySelector('input[data-role="destroy"]');
    var textarea = fields.querySelector('textarea');
    var remove = fields.querySelector('[data-role="remove"]');
    remove.addEventListener('click', function(event) {
      if (destroy) destroy.value = 'true';
      language.disabled = true;
      textarea.disabled = true;
      fields.classList.add('hidden');
    });
  });
}
