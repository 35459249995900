import Rails from '@rails/ujs';

const element = document.getElementById('suppression-logs');

if (element) {
  function update(element, response) {
    const content = response.querySelector('div');
    if (content) {
      element.replaceWith(content);
    }
  }
  element.querySelectorAll('[data-load-from]').forEach(function(div) {
    Rails.ajax({
      url: div.getAttribute('data-load-from'), type: 'GET',
      success: response => update(div, response)
    });
  });

}
