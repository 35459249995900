import {MDCTabBar} from '@material/tab-bar';
import {snackbar} from 'snackbar-util';

const form = document.getElementById('email-edit-form');

if (form) {
  const tabBar = form.querySelector('.mdc-tab-bar');
  const mdcTabBar = new MDCTabBar(tabBar);
  const tabs = form.querySelector('.crm-tabs');
  const inputBody = tabs.querySelector('.crm-tab--0 textarea');
  const preview = tabs.querySelector('.crm-email-preview--html');

  tabBar.addEventListener('MDCTabBar:activated', function(data) {
    const index = data.detail.index;
    preview.innerHTML = inputBody.value;
    tabs.setAttribute('data-active', index);
  });

  const tab2 = tabs.querySelector('.crm-tab--2');
  if (tab2) {
    const button = tab2.querySelector('button');
    const suggestion = tab2.querySelector('textarea');
    button.addEventListener('click', function() {
      inputBody.value = suggestion.value;
      tabBar.querySelectorAll('.mdc-tab')[0].click();
      snackbar("Copied the suggested content");
    });
  }

}
