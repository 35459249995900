import {MDCDrawer} from '@material/drawer';
import {MDCFormField} from '@material/form-field';
import {MDCRadio} from '@material/radio';
import {MDCTextField} from '@material/textfield';

const learnerFilterDrawerElement = document.getElementById('message-learner-filter-drawer');
if (learnerFilterDrawerElement) {
  const learnerFilterDrawer = new MDCDrawer(learnerFilterDrawerElement);
  const learnerFilterIcon = document.getElementById('message-learner-filter-icon');
  learnerFilterIcon.addEventListener('click', function() {
    learnerFilterDrawer.open = true;
  });

  const learnerFilterForm = learnerFilterDrawerElement.querySelector('form');
  var learnersList = document.getElementById('learners-list');
  var learnersListProgressbar = document.getElementById('learners-list-progressbar');
  var learnersListHint = learnersList.querySelector('.crm-empty-side-card-hint');

  const inputLearnerId = document.getElementById('message_learner_id');
  const inputLearnerType = document.getElementById('message_learner_type');
  const fieldEmail = new MDCTextField(document.getElementById('message_email').parentElement);
  const fieldPhome = new MDCTextField(document.getElementById('message_phone').parentElement);
  const fieldFName = new MDCTextField(document.getElementById('message_first_name').parentElement);
  const fieldLName = new MDCTextField(document.getElementById('message_last_name').parentElement);

  document.addEventListener("DOMContentLoaded", function(event) {
    if (inputLearnerId.value) {
      fieldEmail.disabled = true;
      fieldFName.disabled = true;
      fieldLName.disabled = true;
    }
  });

  learnerFilterForm.addEventListener('ajax:before', function(event) {
    if (!learnersListHint.classList.contains('hide')) {
      learnersListHint.classList.add('hide');
    }
    [].forEach.call(learnersList.querySelectorAll('.mdc-form-field'), function(item) {
      learnersList.removeChild(item);
    });
    learnerFilterDrawer.open = false;
    if (learnersListProgressbar.classList.contains('mdc-linear-progress--closed')) {
      learnersListProgressbar.classList.remove('mdc-linear-progress--closed');
    }
  });
  learnerFilterForm.addEventListener('ajax:complete', function(event) {
    if (!learnersListProgressbar.classList.contains('mdc-linear-progress--closed')) {
      learnersListProgressbar.classList.add('mdc-linear-progress--closed');
    }
  });
  learnerFilterForm.addEventListener('ajax:success', function(event) {
    const template = document.getElementById('learners-list-item-template');
    const learners = event.detail[0];

    learners.forEach(function(learner){
      var field = document.importNode(template.content, true);
      var input = field.querySelector('input');
      var id = 'learner-' + learner.id;
      input.id = id;
      input.value = learner.id;
      input.setAttribute('data-id', learner.id);
      input.setAttribute('data-type', learner.type);
      input.setAttribute('data-email', learner.email);
      input.setAttribute('data-fname', learner.first_name);
      input.setAttribute('data-lname', learner.last_name);

      var link = field.querySelector('a');
      link.setAttribute('href', learner.path);

      var label = field.querySelector('label');
      label.setAttribute('for', id);
      field.querySelector('.crm-learner-list-name').textContent = learner.first_name + ' ' + learner.last_name;
      var emailogin = learner.email;
      if (learner.login) { emailogin += (' / ' + learner.login); }
      field.querySelector('.crm-learner-list-courses').textContent = emailogin;
      var formField = new MDCFormField(field);
      formField.input = new MDCRadio(field.querySelector('.mdc-radio'));
      learnersList.appendChild(field);
    });
    [].forEach.call(learnersList.querySelectorAll('input[type="radio"]'), function(input) {
      input.addEventListener('change', function(event) {
        if (input.checked) {
          inputLearnerId.value = input.getAttribute('data-id');
          inputLearnerType.value = input.getAttribute('data-type');

          fieldEmail.value = input.getAttribute('data-email');
          fieldEmail.disabled = true;

          fieldFName.value = input.getAttribute('data-fname');
          fieldFName.disabled = true;

          fieldLName.value = input.getAttribute('data-lname');
          fieldLName.disabled = true;
        }
      });
    });
  });
  learnerFilterForm.addEventListener('ajax:error', function(event) {
    if (learnersListHint.classList.contains('hide')) {
      learnersListHint.classList.remove('hide');
    }
    learnersListHint.textContent = "No learner found with given conditions";
  });
}
