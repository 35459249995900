import {MDCRipple} from '@material/ripple';
import {markAsUnsaved} from './unload-confirmation';

const eventTargetsSelector = '.crm-copy-paste-quote';

function selectedText() {
  if (window.getSelection) {
    return window.getSelection().toString();
  } else if (document.getSelection) {
    return document.getSelection().toString();
  } else {
    return document.selection.createRange().text;
  }
}

function emailContent(src) {
  const part = src.querySelector('.email-part-text');
  const text = (part || src).innerText;
  return text.
    replace(/<!--[\s\S]*?.*-->/g, ''). // Delete html comments
    replace(/\nOn.+wrote:/g, '').      // Delete lines mail client added
    replace(/\n\>.*/g,'');             // delete lines starting with '>'
}

function formatContent(text) {
  return text.
    replace(/</g, '&lt;').
    replace(/>/g, '&gt;').
    replace(/(\n\s*){2,}/g,"\n"). // delete excess newlines
    replace(/\n/g, "\n> ");       // prepend '>' characters
}

function listenCopyPaste(dest) {
  document.querySelectorAll(eventTargetsSelector).forEach(button => {
    const ripple = new MDCRipple(button);
    button.addEventListener('click', function(event){
      const src = document.getElementById(button.getAttribute('data-src'));
      if (dest.offsetWidth <= 0 || dest.offsetHeight <= 0) return; // invisible
      if (!src) return;

      var appendage = selectedText();

      if (!appendage || appendage == ''){
        appendage = emailContent(src);
      }

      appendage = formatContent(appendage);

      // Do nothing if there is no selected text
      if (!appendage || appendage == '') return;

      dest.value = dest.value + "\n\n> " + appendage;
      dest.scrollIntoView({ behavior: 'smooth', block: 'start' });
      markAsUnsaved(dest);
    });
    button.classList.remove('hidden');
  });
}

export { listenCopyPaste };
