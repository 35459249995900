import { Controller } from '@hotwired/stimulus'
import { snackbar } from 'snackbar-util'
import Rails from '@rails/ujs'

export default class extends Controller {
  flashSuccess({detail}) {
    snackbar(detail[0])
  }

  flashError({detail}) {
    snackbar(detail[0])
  }

  submit() {
    Rails.fire(this.element, 'submit')
  }
}
